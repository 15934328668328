<template>
  <div class="threshold-setting-container">
    <div class="unitTitle">{{ t('MS001.unit') }}</div>
    <div class="unit">
      <a-select
        size="large"
        v-model:value="selectUnit"
        style="width: 318px"
        @select="onSelectUnit"
      >
        <a-select-option value="°C">{{ t('MS003.°C') }}</a-select-option>
        <a-select-option value="°F">
          {{ t('MS003.°F') }}
        </a-select-option>
      </a-select>
    </div>
    <div class="rangeTitle">{{ t('MS003.range') }}</div>
    <div class="rangeHigh">
      {{ measureThresholdDisplay.temperatureLow }}
    </div>
    <div class="toSynbol-1">~</div>
    <div class="rangeLow">
      {{ measureThresholdDisplay.temperatureHigh }}
    </div>

    <!-- <a-button class="refactory-btn" @click="confirmRefactory">
      {{ t('MS001.defaultSetting') }}
    </a-button> -->
    <a-button class="confirm-btn" @click="confirmModifyUnit">
      {{ t('MS001.confirmSetting') }}
    </a-button>
  </div>
</template>

<script>
  import { defineComponent, computed, ref, onMounted, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useStore } from 'vuex'
  import { getMeasureStandards } from '@/api/measureThreshold'
  import { message } from 'ant-design-vue'
  export default defineComponent({
    setup() {
      let selectUnit = ref('')
      const store = useStore()
      const { t } = useI18n() // call `useI18n`, and spread `t` from  `useI18n` returning
      const patchMeasureThreshold = (postData) =>
        store.dispatch('measureThreshold/patchMeasureThreshold', postData)
      const putMeasureThreshold = (postData) =>
        store.dispatch('measureThreshold/putMeasureThreshold', postData)
      const clearAllMeasurementData = () =>
        store.dispatch('measurement/clearAllMeasurementData')

      const measureThreshold = computed(
        () => store.state.measureThreshold.measureThreshold
      )
      let measureThresholdDisplay = ref({})

      onMounted(() => {
        if (measureThreshold?.value?.temperature?.length > 0) {
          // 公制
          selectUnit.value = measureThreshold?.value?.temperature[0].unit || ''
          measureThresholdDisplay.value = {
            ...measureThreshold.value.temperature[0],
          }
        } else {
          selectUnit.value = measureThreshold?.value?.temperature?.unit || ''
          measureThresholdDisplay.value = {
            ...measureThreshold.value.temperature,
          }
        }
      })
      watch(measureThreshold, () => {
        if (measureThreshold?.value?.temperature?.length > 0) {
          // 公制
          selectUnit.value = measureThreshold?.value?.temperature[0].unit || ''
          measureThresholdDisplay.value = {
            ...measureThreshold.value.temperature[0],
          }
        } else {
          selectUnit.value = measureThreshold?.value?.temperature?.unit || ''
          measureThresholdDisplay.value = {
            ...measureThreshold.value.temperature,
          }
        }
      })

      const confirmModifyUnit = async () => {
        const data = {
          type: 'temperature',
          unit: selectUnit.value,
        }
        const response = await getMeasureStandards(data)
        if (response.ok !== true) {
          message.warn('Request Failed.')
          return
        }

        const postBody = {
          bloodPressure: {},
          bloodGlucose: {},
          temperature: {},
          bodyWeight: {},
          stature: {},
        }
        if (measureThreshold?.value?.bloodGlucose?.length > 0) {
          postBody.bloodPressure = {
            ...measureThreshold?.value?.bloodPressure[0],
          }
          postBody.bloodGlucose = {
            ...measureThreshold?.value?.bloodGlucose[0],
          }
          postBody.temperature = {
            ...response.result,
          }
          postBody.bodyWeight = {
            ...measureThreshold?.value?.bodyWeight[0],
          }
          postBody.stature = {
            ...measureThreshold?.value?.stature[0],
          }
        } else {
          postBody.bloodPressure = {
            ...measureThreshold?.value?.bloodPressure,
          }
          postBody.bloodGlucose = {
            ...measureThreshold?.value?.bloodGlucose[0],
          }
          postBody.temperature = {
            ...response.result,
          }
          postBody.bodyWeight = {
            ...measureThreshold?.value?.bodyWeight,
          }
          postBody.stature = {
            ...measureThreshold?.value?.stature,
          }
        }

        await putMeasureThreshold(postBody)
        clearAllMeasurementData()
        message.success(t('MS003.confirmSettingComplete'))
      }

      // const confirmRefactory = async () => {
      //   const postBody = {
      //     temperatureUnit: '°C',
      //   }
      //   await patchMeasureThreshold(postBody)
      // }

      const onSelectUnit = async (selectItem) => {
        const data = {
          type: 'temperature',
          unit: selectItem,
        }
        const response = await getMeasureStandards(data)
        if (response.ok === true) {
          measureThresholdDisplay.value = {
            ...response.result,
          }
        }
      }

      return {
        t,
        measureThreshold,
        patchMeasureThreshold,
        putMeasureThreshold,
        clearAllMeasurementData,
        selectUnit,
        measureThresholdDisplay,
        confirmModifyUnit,
        // confirmRefactory,
        onSelectUnit,
      }
    },
  })
</script>

<style lang="less" scoped>
  .titleFont() {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #7b7b7b;
  }
  .symbolFont() {
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    color: #2e2e2e;
  }
  .valueFont() {
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    color: #2e2e2e;
  }
  .btnFont() {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #f7fafc;
  }
  .threshold-setting-container {
    position: relative;
    .unitTitle {
      position: absolute;
      left: 110px;
      top: 62px;
      width: 80px;
      height: 25px;
      .titleFont();
    }
    .unit {
      position: absolute;
      left: 110px;
      top: 95px;
      width: 318px;
      height: 55px;
    }
    .rangeTitle {
      position: absolute;
      left: 110px;
      top: 178px;
      width: 150px;
      height: 25px;
      .titleFont();
    }
    .rangeHigh {
      .valueFont();
      position: absolute;
      left: 110px;
      top: 211px;
      width: 145px;
      height: 55px;
    }
    .toSynbol-1 {
      position: absolute;
      left: 213px;
      top: 230px;
      width: 17px;
      height: 22px;
      .symbolFont();
    }
    .rangeLow {
      .valueFont();
      position: absolute;
      left: 283px;
      top: 211px;
      width: 145px;
      height: 55px;
    }

    .refactory-btn {
      position: absolute;
      left: 300px;
      top: 455px;
      width: 135px;
      height: 40px;
      background: #bdbdbd;
      border-radius: 4px;
    }
    .confirm-btn {
      position: absolute;
      left: 500px;
      top: 95px;
      width: 135px;
      height: 40px;
      background: #4ebaa8;
      border-radius: 4px;
      .btnFont();
    }
  }
</style>
