import { render } from "./HeightWeightThreshold.vue?vue&type=template&id=1b9a8620&scoped=true"
import script from "./HeightWeightThreshold.vue?vue&type=script&lang=js"
export * from "./HeightWeightThreshold.vue?vue&type=script&lang=js"

import "./HeightWeightThreshold.vue?vue&type=style&index=0&id=1b9a8620&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-1b9a8620"
/* hot reload */
if (module.hot) {
  script.__hmrId = "1b9a8620"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1b9a8620', script)) {
    api.reload('1b9a8620', script)
  }
  
  module.hot.accept("./HeightWeightThreshold.vue?vue&type=template&id=1b9a8620&scoped=true", () => {
    api.rerender('1b9a8620', render)
  })

}

script.__file = "src/views/meaureThreshold/components/HeightWeightThreshold.vue"

export default script