<template>
  <div class="threshold-setting-container">
    <div class="unitTitle">{{ t('MS001.unit') }}</div>
    <div class="unit">
      <a-select
        size="large"
        v-model:value="selectUnit"
        style="width: 318px"
        disabled
      >
        <a-select-option value="mmHg">{{ t('MS001.mmHg') }}</a-select-option>
        <a-select-option value="kPa">{{ t('MS001.kPa') }}</a-select-option>
      </a-select>
    </div>
    <div class="rangeTitle">{{ t('MS001.range') }}</div>
    <div class="systolicTitle">{{ t('MS001.systolic') }}</div>
    <div class="systolicHigh">
      {{ measureThresholdDisplay.systolicLow }}
    </div>
    <div class="toSynbol-1">~</div>
    <div class="systolicLow">
      {{ measureThresholdDisplay.systolicHigh }}
    </div>

    <div class="diastolicTitle">{{ t('MS001.diastolic') }}</div>
    <div class="diastolicHigh">
      {{ measureThresholdDisplay.diastolicLow }}
    </div>
    <div class="toSynbol-2">~</div>
    <div class="diastolicLow">
      {{ measureThresholdDisplay.diastolicHigh }}
    </div>

    <!-- <div class="ppTitle">{{ t('MS001.pp') }}</div>
    <div class="ppHigh">
      {{ measureThreshold.ppHigh }}
    </div>
    <div class="toSynbol-3">~</div>
    <div class="ppLow">
      {{ measureThreshold.ppLow }}
    </div> -->

    <div class="pulseTitle">{{ t('MS001.pulse') }}</div>
    <div class="pulseHigh">
      {{ measureThresholdDisplay.bpPulseLow }}
    </div>
    <div class="toSynbol-4">~</div>
    <div class="pulseLow">
      {{ measureThresholdDisplay.bpPulseHigh }}
    </div>

    <!-- <a-button class="refactory-btn" @click="confirmRefactory">
      {{ t('MS001.defaultSetting') }}
    </a-button>
    <a-button class="confirm-btn" @click="confirmModifyUnit">
      {{ t('MS001.confirmSetting') }}
    </a-button> -->
  </div>
</template>

<script>
  import { defineComponent, computed, ref, onMounted, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useStore } from 'vuex'
  export default defineComponent({
    setup() {
      let selectUnit = ref('')
      const store = useStore()
      const { t } = useI18n() // call `useI18n`, and spread `t` from  `useI18n` returning
      const patchMeasureThreshold = (postData) =>
        store.dispatch('measureThreshold/patchMeasureThreshold', postData)
      const putMeasureThreshold = (postData) =>
        store.dispatch('measureThreshold/putMeasureThreshold', postData)
      const measureThreshold = computed(
        () => store.state.measureThreshold.measureThreshold
      )
      let measureThresholdDisplay = ref({})

      onMounted(() => {
        if (measureThreshold?.value?.bloodPressure?.length > 0) {
          selectUnit.value =
            measureThreshold?.value?.bloodPressure[0].unit || ''
          measureThresholdDisplay.value = {
            ...measureThreshold.value.bloodPressure[0],
          }
        } else {
          selectUnit.value = measureThreshold?.value?.bloodPressure?.unit || ''
          measureThresholdDisplay.value = {
            ...measureThreshold.value.bloodPressure,
          }
        }
      })
      watch(measureThreshold, () => {
        if (measureThreshold?.value?.bloodPressure?.length > 0) {
          selectUnit.value =
            measureThreshold?.value?.bloodPressure[0].unit || ''
          measureThresholdDisplay.value = {
            ...measureThreshold.value?.bloodPressure[0],
          }
        } else {
          selectUnit.value = measureThreshold?.value?.bloodPressure?.unit || ''
          measureThresholdDisplay.value = {
            ...measureThreshold.value.bloodPressure,
          }
        }
      })

      // const confirmModifyUnit = async () => {
      //   const postBody = {
      //     bpUnit: selectUnit.value,
      //   }
      //   await patchMeasureThreshold(postBody)
      // }

      // const confirmRefactory = async () => {
      //   const postBody = {
      //     bpUnit: 'mmHg',
      //   }
      //   await patchMeasureThreshold(postBody)
      // }

      return {
        t,
        selectUnit,
        measureThreshold,
        putMeasureThreshold,
        patchMeasureThreshold,
        measureThresholdDisplay,
        // confirmModifyUnit,
        // confirmRefactory,
      }
    },
  })
</script>

<style lang="less" scoped>
  .titleFont() {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #7b7b7b;
  }
  .symbolFont() {
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    color: #2e2e2e;
  }
  .valueFont() {
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    color: #2e2e2e;
  }

  .threshold-setting-container {
    position: relative;
    .unitTitle {
      position: absolute;
      left: 110px;
      top: 62px;
      width: 80px;
      height: 25px;
      .titleFont();
    }
    .unit {
      position: absolute;
      left: 110px;
      top: 95px;
      width: 318px;
      height: 55px;
    }
    .rangeTitle {
      position: absolute;
      left: 110px;
      top: 178px;
      width: 200px;
      height: 25px;
      .titleFont();
    }
    .systolicTitle {
      position: absolute;
      left: 110px;
      top: 244px;
      width: 80px;
      height: 25px;
      .titleFont();
    }
    .systolicHigh {
      .valueFont();
      position: absolute;
      left: 110px;
      top: 285px;
      width: 145px;
      height: 55px;
    }
    .toSynbol-1 {
      position: absolute;
      left: 213px;
      top: 297px;
      width: 17px;
      height: 22px;
      .symbolFont();
    }
    .systolicLow {
      .valueFont();
      position: absolute;
      left: 283px;
      top: 285px;
      width: 145px;
      height: 55px;
    }

    .diastolicTitle {
      position: absolute;
      left: 475px;
      top: 244px;
      width: 80px;
      height: 25px;
      .titleFont();
    }
    .diastolicHigh {
      .valueFont();
      position: absolute;
      left: 475px;
      top: 285px;
      width: 145px;
      height: 55px;
    }
    .toSynbol-2 {
      position: absolute;
      left: 578px;
      top: 297px;
      width: 17px;
      height: 22px;
      .symbolFont();
    }
    .diastolicLow {
      .valueFont();
      position: absolute;
      left: 648px;
      top: 285px;
      width: 145px;
      height: 55px;
    }

    .ppTitle {
      position: absolute;
      left: 110px;
      top: 360px;
      width: 300px;
      height: 25px;
      .titleFont();
    }
    .ppHigh {
      .valueFont();
      position: absolute;
      left: 110px;
      top: 401px;
      width: 145px;
      height: 55px;
    }
    .toSynbol-3 {
      position: absolute;
      left: 213px;
      top: 413px;
      width: 17px;
      height: 22px;
      .symbolFont();
    }
    .ppLow {
      .valueFont();
      position: absolute;
      left: 283px;
      top: 401px;
      width: 145px;
      height: 55px;
    }

    .pulseTitle {
      // coyp from pp
      position: absolute;
      left: 110px;
      top: 360px;
      width: 300px;
      height: 25px;
      .titleFont();
      // position: absolute;
      // left: 475px;
      // top: 360px;
      // width: 80px;
      // height: 25px;
      // .titleFont();
    }
    .pulseHigh {
      // copy from pp
      .valueFont();
      position: absolute;
      left: 110px;
      top: 401px;
      width: 145px;
      height: 55px;
      // .valueFont();
      // position: absolute;
      // left: 475px;
      // top: 401px;
      // width: 145px;
      // height: 55px;
    }
    .toSynbol-4 {
      // copy from pp
      position: absolute;
      left: 213px;
      top: 413px;
      width: 17px;
      height: 22px;
      .symbolFont();
      // position: absolute;
      // left: 578px;
      // top: 413px;
      // width: 17px;
      // height: 22px;
      // .symbolFont();
    }
    .pulseLow {
      // copy from pp
      .valueFont();
      position: absolute;
      left: 283px;
      top: 401px;
      width: 145px;
      height: 55px;
      // .valueFont();
      // position: absolute;
      // left: 648px;
      // top: 401px;
      // width: 145px;
      // height: 55px;
    }

    .refactory-btn {
      position: absolute;
      left: 300px;
      top: 455px;
      width: 135px;
      height: 40px;
      background: #bdbdbd;
      border-radius: 4px;
    }
    .confirm-btn {
      position: absolute;
      left: 470px;
      top: 455px;
      width: 135px;
      height: 40px;
      background: #4ebaa8;
      border-radius: 4px;
    }
  }
</style>
