<template>
  <div class="measure-thresthold-container">
    <div class="tab-control-area">
      <div
        @click="tabClick('BloodPressureThreshold')"
        :class="{ active: tabName == 'BloodPressureThreshold' }"
      >
        {{ t('MS000.bloodPressureCaptial') }}
      </div>
      <div
        @click="tabClick('GlucoseThreshold')"
        :class="{ active: tabName == 'GlucoseThreshold' }"
      >
        {{ t('MS000.glucoseCaptial') }}
      </div>
      <div
        @click="tabClick('HeightWeightThreshold')"
        :class="{ active: tabName == 'HeightWeightThreshold' }"
      >
        {{ t('MS000.bodyHeightWeightCaptial') }}
      </div>
      <div
        @click="tabClick('TemperatureThreshold')"
        :class="{ active: tabName == 'TemperatureThreshold' }"
      >
        {{ t('MS000.bodyTemperatureCaptial') }}
      </div>
    </div>
    <div class="tab-middle-area"></div>
    <div class="tab-function-area">
      <transition>
        <component :is="tabName"></component>
      </transition>
    </div>
  </div>
</template>
<script>
  import { defineComponent, ref, onMounted, computed } from 'vue'
  import BloodPressureThreshold from './components/BloodPressureThreshold'
  import GlucoseThreshold from './components/GlucoseThreshold'
  import TemperatureThreshold from './components/TemperatureThreshold'
  import HeightWeightThreshold from './components/HeightWeightThreshold'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  import { accountExpiredCheck } from '../common/accountExpiredCheck.js'

  export default defineComponent({
    components: {
      BloodPressureThreshold,
      GlucoseThreshold,
      TemperatureThreshold,
      HeightWeightThreshold,
    },
    setup() {
      const { t } = useI18n()

      const store = useStore()
      let tabName = ref('BloodPressureThreshold')
      const tabClick = function (name) {
        tabName.value = name
      }
      const getMeasureThreshold = () =>
        store.dispatch('measureThreshold/getMeasureThreshold')
      const measureThreshold = computed(
        () => store.state.measureThreshold.measureThreshold
      )
      const expiredTs = computed(() => store.state.user.expiredTs)

      onMounted(() => {
        accountExpiredCheck(expiredTs.value)
        getMeasureThreshold()
      })

      return {
        t,
        tabName,
        tabClick,
        getMeasureThreshold,
        measureThreshold,
      }
    },
  })
</script>
<style lang="less" scoped>
  .measure-thresthold-container {
    padding-top: 22px;
    background-color: #e5e5e5;

    .tab-control-area {
      margin: 0 auto;
      width: 935px;
      height: 65px;
      background: #fafafa;
      border: 4.04878px solid #ffffff;
      box-shadow: 4.04878px 4.04878px 4.04878px rgba(0, 0, 0, 0.03),
        inset -6.07317px -10.122px 6.07317px rgba(255, 255, 255, 0.6),
        inset 2.02439px 4.04878px 16.1951px rgba(0, 0, 0, 0.13);
      border-radius: 24.2927px;
      display: flex;
      flex-direction: row;
      > div {
        flex: 1;
        cursor: pointer;

        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        /* or 152% */

        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        opacity: 0.5;
        text-shadow: 0px 0px 6.07317px rgba(188, 188, 188, 0.15);
      }
      .active {
        background: #4dbaa7;
        box-shadow: -8.09756px -8.09756px 16.1951px rgba(255, 255, 255, 0.55),
          6.07317px 6.07317px 16.1951px rgba(0, 0, 0, 0.05);
        border-radius: 20.2439px;
      }
    }
    .tab-middle-area {
      width: 100%;
      height: 20px;
    }
    .tab-function-area {
      width: 900px;
      height: 550px;
      margin: 0 auto;
      background: #ffffff;
    }
  }
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }

  .v-enter-to,
  .v-leave-from {
    opacity: 1;
  }
</style>
