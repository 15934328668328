import { render } from "./BloodPressureThreshold.vue?vue&type=template&id=5fe89738&scoped=true"
import script from "./BloodPressureThreshold.vue?vue&type=script&lang=js"
export * from "./BloodPressureThreshold.vue?vue&type=script&lang=js"

import "./BloodPressureThreshold.vue?vue&type=style&index=0&id=5fe89738&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-5fe89738"
/* hot reload */
if (module.hot) {
  script.__hmrId = "5fe89738"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5fe89738', script)) {
    api.reload('5fe89738', script)
  }
  
  module.hot.accept("./BloodPressureThreshold.vue?vue&type=template&id=5fe89738&scoped=true", () => {
    api.rerender('5fe89738', render)
  })

}

script.__file = "src/views/meaureThreshold/components/BloodPressureThreshold.vue"

export default script